import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, Modal, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuthContext } from '../../context/AuthContext';

import styles from './Regulations.module.scss';

const RegulationsModal = () => {
  const { t } = useTranslation();

  const { user, acceptRegulations } = useAuthContext();

  const form = useForm({
    initialValues: {
      accept: false,
      marketing: false,
    },
    validate: {
      accept: (value) => (value === true ? null : t('FIELD_REQUIRED')),
    },
  });

  const submitHandler = (values) => {
    if (!user) return;
    let _data = {};
    _data.settings = {
      ...user.settings,
      regulations: 'accepted',
      regulations_version: import.meta.env.VITE_REGULATIONS_VERSION,
    };
    if (values.marketing) {
      _data.marketing = values.marketing;
    }
    if (_data) acceptRegulations(_data);
  };

  return (
    <Modal
      opened={true}
      onClose={() => {}}
      centered
      withCloseButton={false}
      overlayBlur={10}
      size={500}
    >
      <form
        onSubmit={form.onSubmit((values) => submitHandler(values))}
        className={styles.regulations}
      >
        <p className='medium-font'>{t('REGULATIONS_INFO')}</p>
        <Checkbox
          label={
            <Trans
              i18nKey='common:ACCEPT_REGULATIONS'
              components={{
                a: (
                  <a
                    href='https://ferguson-digital.eu/inteligentny-dom/ferguson-smarthome2-smart_alert-regulations-pl.pdf'
                    target='_blank'
                    rel='noreferrer'
                  ></a>
                ),
              }}
            />
          }
          value={form.accept}
          {...form.getInputProps('accept')}
          required
        />
        {user && !user.marketing && (
          <Tooltip
            label={t('common:NEWSLETTER_TOOLTIP')}
            width={500}
            multiline={true}
            position='bottom'
          >
            <div>
              <Checkbox
                label={t('common:ACCEPT_NEWSLETTER', { email: user.email })}
                value={form.marketing}
                {...form.getInputProps('marketing')}
              />
            </div>
          </Tooltip>
        )}
        <Button type='submit'>{t('SUBMIT')}</Button>
      </form>
    </Modal>
  );
};

export default RegulationsModal;
