const SensorIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      width='26'
      height='19.799'
      viewBox='0 0 26 19.799'
    >
      <path
        d='M12.535,20.425a6.667,6.667,0,0,1,0-9.427m9.429,0a6.667,6.667,0,0,1,0,9.427M8.765,24.2a12,12,0,0,1,0-16.971m16.971,0a12,12,0,0,1,0,16.971M17.25,15.712h.014v.012H17.25Zm.667,0a.667.667,0,1,1-.667-.667A.667.667,0,0,1,17.917,15.712Z'
        transform='translate(-4.25 -5.813)'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};
export default SensorIcon;
