const CubeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24.017 26.342'
      stroke='currentColor'
      width='26.003'
      height='24.153'
    >
      <path
        d='M25.333,8.333,14.667,3,4,8.333m21.333,0L14.667,13.667M25.333,8.333V21.667L14.667,27m0-13.333L4,8.333m10.667,5.333V27M4,8.333V21.667L14.667,27'
        transform='translate(-2.658 -2)'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};

export default CubeIcon;
