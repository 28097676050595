import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from "dayjs";
import axios from '../../api/axios';

const ADD_HUB = 'ADD_HUB';
const EDIT_HUB = 'EDIT_HUB';
const DELETE_HUB = 'DELETE_HUB';

const ADD_SENSOR = 'ADD_SENSOR';
const EDIT_SENSOR = 'EDIT_SENSOR';
const DELETE_SENSOR = 'DELETE_SENSOR';

export const fetchContracts = createAsyncThunk(
  'clients/fetchContracts',
  async () => {
    const activeContracts = await axios.get('contracts?type=active&limit=1000');
    const inactiveContracts = await axios.get('contracts?type=inactive&limit=1000');
    const active = await activeContracts.data;
    const inactive = await inactiveContracts.data;

    const contracts = [...active, ...inactive].map((contract) => ({
      ...contract,
      active: dayjs().valueOf() >= contract.since && dayjs().valueOf() <= contract.until,
    }));

    return {
      contracts: contracts,
    };
  }
);

export const fetchHubs = createAsyncThunk('clients/fetchHubs', async () => {
  const response = await axios.get('hubs?limit=1000');
  console.log(response)
  return {
    hubs: response.data,
  };
});

export const fetchSensors = createAsyncThunk(
  'clients/fetchSensors',
  async () => {
    const response = await axios.get('sensors?limit=1000');
    return {
      sensors: response.data,
    };
  }
);

export const addContract = createAsyncThunk(
  'clients/addContract',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post('contract', data);
      return { data: data, contract_id: response.data.contract_id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editContract = createAsyncThunk(
  'clients/editContract',
  async (
    { data, type, currentDevice, previousDevice },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post('contract', data);
      return {
        data,
        result: response.data,
        type,
        currentDevice,
        previousDevice,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    contracts: [],
    hubs: [],
    sensors: [],
    status: 'idle',
    error: null,
  },
  extraReducers(builder) {
    builder
      // fetch contracts
      .addCase(fetchContracts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContracts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contracts = action.payload.contracts;
        console.debug('Fetched contracts:', state.contracts);
      })
      .addCase(fetchContracts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot fetch contracts:', state.error);
      })
      // fetch hubs
      .addCase(fetchHubs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHubs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.hubs = action.payload.hubs;
        console.debug('Fetched hubs:', state.hubs);
      })
      .addCase(fetchHubs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot fetch hubs:', state.error);
      })
      // fetch sensors
      .addCase(fetchSensors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSensors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sensors = action.payload.sensors;
        console.debug('Fetched sensors:', state.sensors);
      })
      .addCase(fetchSensors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot fetch sensors:', state.error);
      })
      // add contract
      .addCase(addContract.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addContract.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const newContract = {
          ...action.payload.data,
          contract_id: action.payload.contract_id,
          active: dayjs().valueOf() >= action.payload.data.since && dayjs().valueOf() <= action.payload.data.until,
        };
        state.contracts.push(newContract);
        console.debug('Contract added:', newContract);
      })
      .addCase(addContract.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot add contract:', state.error);
      })
      // edit contract
      .addCase(editContract.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editContract.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.contracts.findIndex(
          (contract) => contract.contract_id === action.payload.data.contract_id
        );
        const currentContract = state.contracts[index];
        state.contracts[index] = {
          ...state.contracts[index],
          ...action.payload.data,
        };
        if (action.payload.data.since || action.payload.data.until) {
          const sinceTs = action.payload.data.since || currentContract.since;
          const untilTs = action.payload.data.until || currentContract.until;
          state.sensors = state.sensors.map((sensor) => {
            if (sensor.contract_id === action.payload.data.contract_id) {
              const _sensor = {...sensor};
              _sensor.since = sinceTs;
              _sensor.until = untilTs;
              _sensor.active = dayjs().valueOf() >= sinceTs && dayjs().valueOf() <= untilTs;
              return _sensor;
            }
            return sensor;
          });
          state.hubs = state.hubs.map((hub) => {
            if (hub.contract_id === action.payload.data.contract_id) {
              const _hub = {...hub};
              _hub.since = sinceTs;
              _hub.until = untilTs;
              return _hub;
            }
            return hub;
          });
        }
        if (action.payload.type === ADD_HUB) {
          const newHub = {
            company_contract_id: currentContract.company_contract_id,
            contract_id: currentContract.contract_id,
            hub_id: action.payload.currentDevice,
            sensor_count: 0,
            since: currentContract.since,
            treesat_user: '',
            until: currentContract.until,
          };
          state.hubs.push(newHub);
          console.debug('Hub added:', newHub);
        }
        if (action.payload.type === EDIT_HUB) {
          const currentDevice = action.payload.currentDevice;
          const previousDevice = action.payload.previousDevice;

          let index = state.hubs.findIndex((h) => h.hub_id === previousDevice);
          if (index !== -1) {
            state.hubs[index] = {
              ...state.hubs[index],
              hub_id: currentDevice,
            };
          }
          console.debug('Hub edited', state.hubs[index]);
        }
        if (action.payload.type === DELETE_HUB) {
          state.hubs = state.hubs.filter(
            (hub) => hub.hub_id !== action.payload.currentDevice.hub_id
          );
          console.debug('Hub deleted', action.payload.currentDevice.hub_id);
        }
        if (action.payload.type === ADD_SENSOR) {
          const newSensor = {
            active: currentContract.active,
            company_contract_id: currentContract.company_contract_id,
            contract_id: currentContract.contract_id,
            hub_id: null,
            sensor_id: action.payload.currentDevice,
            since: currentContract.since,
            until: currentContract.until,
          };
          state.sensors.push(newSensor);
          console.debug('Sensor added:', newSensor);
        }
        if (action.payload.type === EDIT_SENSOR) {
          const currentDevice = action.payload.currentDevice;
          const previousDevice = action.payload.previousDevice;

          let index = state.sensors.findIndex(
            (sensor) => sensor.sensor_id === previousDevice
          );
          if (index !== -1) {
            state.sensors[index] = {
              ...state.sensors[index],
              sensor_id: currentDevice,
            };
          }
          console.debug('Sensor edited', state.sensors[index]);
        }
        if (action.payload.type === DELETE_SENSOR) {
          state.sensors = state.sensors.filter(
            (sensor) =>
              sensor.sensor_id !== action.payload.currentDevice.sensor_id
          );
          console.debug('Sensor deleted');
        }
        console.debug('Contract updated:', action.payload.data);
      })
      .addCase(editContract.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot edit contract:', state.error);
      });
  },
});

export const clientsActions = clientsSlice.actions;

export default clientsSlice.reducer;
