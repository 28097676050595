import './App.scss';

import {useEffect, lazy, Suspense, useState} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {Provider} from 'react-redux';
import {Loader, MantineProvider} from '@mantine/core';
import {NotificationsProvider as MantineNotificationsProvider} from '@mantine/notifications';
import 'dayjs/locale/pl';

import store from './store/store';
import ProtectedLayout from './components/ProtectedLayout';

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Login = lazy(() => import('./pages/Login/Login'));
const Clients = lazy(() => import('./pages/Clients/Clients'));
const Hubs = lazy(() => import('./pages/Hubs/Hubs'));
const Alerts = lazy(() => import('./pages/Alerts/Alerts'));
const Sensors = lazy(() => import('./pages/Sensors/Sensors'));
const ProfileSettings = lazy(() =>
    import('./pages/ProfileSettings/ProfileSettings')
);
const Help = lazy(() => import('./pages/Help/Help'));
const Channels = lazy(() => import('./pages/Channels/Channels'));

function App() {
    const location = useLocation();
    const [initialPathname, setInitialPathname] = useState(null);

    if (process.env.NODE_ENV !== "development") {
        console.log = () => {};
        console.debug = () => {};
    }

    useEffect(() => {
        document.title = 'SH 2.0 Alert System';
    }, []);

    useEffect(() => {
        if (initialPathname) return;
        setInitialPathname(location.pathname);
    }, [location]);

    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            datesLocale='pl'
            theme={{
                globalStyles: () => ({
                    body: {fontFamily: 'Roboto, Montserrat', fontSizes: 16 + 'px'},
                }),
                components: {
                    Container: {
                        defaultProps: {
                            sizes: {
                                xs: 576,
                                sm: 768,
                                md: 992,
                                lg: 1200,
                                xl: 1400,
                            },
                        },
                    },
                },
            }}
        >
            <MantineNotificationsProvider
                position='top-right'
                limit={5}
                autoClose={3000}
                zIndex={3000}
                containerWidth={300}
                className='notification'
            >
                <Provider store={store}>
                    <Routes>
                        <Route element={<ProtectedLayout/>}>
                            <Route index path='dashboard' element={
                                <Suspense fallback={<Loader className='loader'/>}>
                                    <Dashboard/>
                                </Suspense>
                            }/>
                            <Route
                                path='clients'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <Clients/>
                                    </Suspense>
                                }
                            />
                            <Route
                                path='hubs'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <Hubs/>
                                    </Suspense>
                                }
                            />
                            <Route
                                path='sensors'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <Sensors/>
                                    </Suspense>
                                }
                            />
                            <Route
                                path='alerts'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <Alerts/>
                                    </Suspense>
                                }
                            />
                            <Route
                                path='profile'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <ProfileSettings/>
                                    </Suspense>
                                }
                            />
                            <Route
                                path='help'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <Help/>
                                    </Suspense>
                                }
                            />
                            <Route
                                path='channels'
                                element={
                                    <Suspense fallback={<Loader className='loader'/>}>
                                        <Channels/>
                                    </Suspense>
                                }
                            />
                            <Route path='*' element={<Navigate to='dashboard'/>}/>
                        </Route>
                        <Route
                            path='login'
                            element={
                                <Suspense fallback={<Loader className='loader'/>}>
                                    <Login initialPathname={initialPathname}/>
                                </Suspense>
                            }
                        />
                    </Routes>
                </Provider>
            </MantineNotificationsProvider>
        </MantineProvider>
    );
}

export default App;
