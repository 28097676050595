import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await axios.get('users?limit=1000');
  return response.data;
});

export const addUser = createAsyncThunk(
  'users/addUser',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post('users', data);
      return { data, user_id: response.data.user_id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editUser = createAsyncThunk(
  'users/editUser',
  async ({ data, id }) => {
    let url;
    // if id is set edit other user, otherwise edit current user
    if (id) {
      url = 'users';
    } else {
      url = 'user';
    }
    const response = await axios.post(url, data);
    return { response: response.data, user_id: id, data: data };
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`users?user_id=${user_id}`);
      return { user_id, response: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  extraReducers(builder) {
    builder
      // fetch users
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        console.debug('Fetched users:', state.data);
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot fetch users:', state.error);
      })
      // add user
      .addCase(addUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        let newUser = {
          ...action.payload.data,
          user_id: action.payload.user_id,
        };
        state.data.push(newUser);
        console.debug('User added:', action.payload.response);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot add user:', state.error);
      })
      // edit user
      .addCase(editUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.user_id) {
          const index = state.data.findIndex(
            (el) => el.user_id === action.payload.data.user_id
          );
          state.data[index] = { ...state.data[index], ...action.payload.data };
        }
        console.debug('User edited:', action.payload.response);
      })
      .addCase(editUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot edit user:', state.error);
      })
      // delete user
      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = state.data.filter(
          (user) => user.user_id !== action.payload.user_id
        );
        console.debug('User deleted:', action.payload.user_id);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot delete user:', state.error);
      });
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
