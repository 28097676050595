import {useEffect, useState} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {useAuthContext} from '../context/AuthContext';
import Navigation from './Navigation/Navigation';
import {fetchChannels} from '../store/slices/channels-slice';
import {
    fetchContracts, fetchHubs, fetchSensors,
} from '../store/slices/clients-slice';
import {fetchUsers} from '../store/slices/users-slice';
import RegulationsModal from './RegulationsModal/RegulationsModal';
import ChangeLog from "./ChangeLog/ChangeLog";
import AppVersion from "../version.js";
import extractChanges from "./ChangeLog/extractChanges.js";

const ProtectedLayout = () => {
    const {isAuthenticated, user, isStatuteAccepted, updateUserSettings} = useAuthContext();

    const dispatch = useDispatch();

    const [showChangelog, setShowChangelog] = useState(false);
    const [changelogData, setChangelogData] = useState(null);

    useEffect(() => {
        if (!isAuthenticated || !user) return;
        dispatch(fetchChannels());
        dispatch(fetchContracts());
        dispatch(fetchHubs());
        dispatch(fetchSensors());
        dispatch(fetchUsers());
    }, [dispatch, isAuthenticated, user]);

    // Update last version on server
    useEffect(() => {
        if (!user || !user.settings) return;

        const lastVersionFromServer = user.settings.last_version;
        let appVersion = AppVersion.version;

        if (appVersion.includes('rc')) {
            const _version = appVersion.split('-');
            appVersion = _version[0] + '-' + _version[1];
        }

        if (lastVersionFromServer === appVersion) return;

        if ((lastVersionFromServer && lastVersionFromServer !== appVersion) || (user.settings && !lastVersionFromServer)) {
            setShowChangelog(true);
            let data = extractChanges(lastVersionFromServer || 'v0.0.0', appVersion);
            data.reverse();
            setChangelogData(data);
        }
        const _settings = {...user.settings};
        _settings.last_version = appVersion;
        updateUserSettings(_settings);
    }, [user]);

    return !user || !isAuthenticated ? (
        <Navigate to='/login'/>
    ) : (
        <>
            {!isStatuteAccepted && <RegulationsModal/>}
            {showChangelog && changelogData &&
                <ChangeLog data={changelogData} show={showChangelog} setShow={setShowChangelog}/>}
            <Navigation/>
            <main>
                <Outlet/>
            </main>
        </>
    );
};

export default ProtectedLayout;
