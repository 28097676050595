import axios from 'axios';
import dayjs from 'dayjs';

const baseUrl = import.meta.env.VITE_APP_API_URL;

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const sessionId = localStorage.getItem('session_id');
    if (sessionId) {
      config.headers = {
        'X-session-ID': sessionId,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    const sessionLifetime = dayjs()
      .add(localStorage.getItem('lifetime'), 'seconds')
      .unix();
    localStorage.setItem('session_lifetime', sessionLifetime);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;

export const getErrorMessage = (error) => {
  return Object.values(error.response.data.error)[0];
};
