import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Menu } from '@mantine/core';
import { cleanNotifications } from '@mantine/notifications';
import { useAuthContext } from '../../context/AuthContext';

import Logo from '../../icons/logo';
import TemplateIcon from '../../icons/template-icon';
import UsersIcon from '../../icons/users-icon';
import CubeIcon from '../../icons/cube-icon';
import SensorIcon from '../../icons/sensor-icon';
import AlertIcon from '../../icons/alert-icon';
import SignOutIcon from '../../icons/sign-out-icon';
import UserIcon from '../../icons/user-icon';
import HelpIcon from '../../icons/help-icon';
import SettingsIcon from '../../icons/settings-icon';

import styles from './Navigation.module.scss';

const Navigation = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuthContext();

  return (
    <nav className={styles.nav}>
      <div className={styles['logo-container']}>
        <NavLink to='dashboard' aria-label='dashboard'>
          <Logo />
        </NavLink>
      </div>
      <ul>
        <li>
          <NavLink
            to='dashboard'
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            <TemplateIcon />
            {t('DASHBOARD')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='clients'
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            <UsersIcon />
            {t('CLIENTS')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='hubs'
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            <CubeIcon />
            {t('HUBS')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='sensors'
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            <SensorIcon />
            {t('SENSORS')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='alerts'
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            <AlertIcon />
            {t('ALERTS')}
          </NavLink>
        </li>
      </ul>

      <Menu
        width={250}
        transition='scale-y'
        transitionDuration={200}
        shadow='0px 5px 20px #00000019'
        radius='10px'
        offset={0}
      >
        <Menu.Target>
          <Button className={`${styles['user-button']} transparent`}>
            {user && (
              <p className={styles.role}>
                {t(`common:${user.role.toUpperCase()}`).toUpperCase()}
              </p>
            )}
            <UserIcon />
            {user && (
              <p className={styles.username}>
                {t('LOGGED_AS')}: {user.login ? user.login : `${user.email}`}
              </p>
            )}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item style={{ padding: 0 }}>
            <Link to='profile'>
              <UserIcon size={14} />
              {t('PROFILE_AND_SETTINGS')}
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item style={{ padding: 0 }}>
            <Link to='channels'>
              <SettingsIcon size={14} />
              {t('NOTIFICATION_CHANNEL')}
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item style={{ padding: 0 }}>
            <Link to='help'>
              <HelpIcon size={14} />
              {t('HELP')}
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            style={{ padding: 0 }}
            onClick={() => {
              logout();
              cleanNotifications();
            }}
          >
            <Link to='/login'>
              <SignOutIcon size={14} />
              {t('LOGOUT')}
            </Link>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </nav>
  );
};

export default Navigation;
