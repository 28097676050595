import {useTranslation} from 'react-i18next';
import {Button, Modal, ScrollArea} from '@mantine/core';
import {bool, func, arrayOf, object} from 'prop-types';

const ChangeLog = ({show, setShow, data}) => {
    const {t} = useTranslation(['help', 'common']);

    return (
        <Modal
            opened={show}
            onClose={() => setShow(false)}
            centered
            withCloseButton={false}
            size={700}
        >
            <p className='semibold' style={{textAlign: 'center', margin: 0}}>{t('CHANGELOG_TITLE')}</p>
            <hr style={{margin: '1rem 0'}}/>
            <ScrollArea style={{height: 250, paddingBottom: '1rem'}}>
                {data && data.map((o) => (
                    <div key={o.version}>
                        <p className='semibold' style={{margin: 0}}>{o.version}</p>
                        <ul>
                            {o.changes.map((change, index) => <li key={`${o.version}_${index}`}>{change}</li>)}
                        </ul>
                    </div>
                ))}
            </ScrollArea>
            <Button
                className='basic'
                style={{display: 'flex', margin: '0 auto'}}
                onClick={() => setShow(false)}
            >
                {t('common:CLOSE')}
            </Button>
        </Modal>
    );
};

ChangeLog.propTypes = {
    show: bool.isRequired,
    setShow: func.isRequired,
    data: arrayOf(object).isRequired
};

export default ChangeLog;
