import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/axios';

export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels',
  async () => {
    const response = await axios.get('channels?limit=1000');

    return response.data;
  }
);

export const addChannel = createAsyncThunk(
  'channels/addChannel',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post('channels', data);
      return { data, channel_id: response.data.channel_id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editChannel = createAsyncThunk(
  'channels/editChannel',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post('channels', data);
      return { data, response: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteChannel = createAsyncThunk(
  'channels/deleteChannel',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `channels?channel_id=${data.channel_id}`
      );
      return { data, response: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const channelsSlice = createSlice({
  name: 'channels',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  extraReducers(builder) {
    builder
      // fetch channels
      .addCase(fetchChannels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        console.debug('Fetched channels: ', state.data);
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot fetch channels: ', state.error);
      })
      // add channel
      .addCase(addChannel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addChannel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const newChannel = {
          ...action.payload.data,
          channel_id: action.payload.channel_id,
        };
        state.data.push(newChannel);
        console.debug('Channel added: ', newChannel);
      })
      .addCase(addChannel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot add channel: ', state.error);
      })
      // edit channel
      .addCase(editChannel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editChannel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(
          (el) => el.channel_id === action.payload.data.channel_id
        );
        state.data[index] = { ...state.data[index], ...action.payload.data };
        console.debug('Channel edited: ', action.payload.data);
      })
      .addCase(editChannel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot edit channel: ', state.error);
      })
      // delete channel
      .addCase(deleteChannel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteChannel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = state.data.filter(
          (el) => el.channel_id !== action.payload.data.channel_id
        );
        console.debug('Channel deleted: ', action.payload.data);
      })
      .addCase(deleteChannel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.debug('Cannot delete channel: ', state.error);
      });
  },
});

export const channelsActions = channelsSlice.actions;

export default channelsSlice.reducer;
