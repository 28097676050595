const TemplateIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path
        d='M4,5.375A1.375,1.375,0,0,1,5.375,4h19.25A1.375,1.375,0,0,1,26,5.375v2.75A1.375,1.375,0,0,1,24.625,9.5H5.375A1.375,1.375,0,0,1,4,8.125Zm0,11A1.375,1.375,0,0,1,5.375,15h8.25A1.375,1.375,0,0,1,15,16.375v8.25A1.375,1.375,0,0,1,13.625,26H5.375A1.375,1.375,0,0,1,4,24.625Zm16.5,0A1.375,1.375,0,0,1,21.875,15h2.75A1.375,1.375,0,0,1,26,16.375v8.25A1.375,1.375,0,0,1,24.625,26h-2.75A1.375,1.375,0,0,1,20.5,24.625Z'
        transform='translate(-3 -3)'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};

export default TemplateIcon;
