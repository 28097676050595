const UsersIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      width='26.003'
      height='24.153'
      viewBox='0 0 26.003 24.153'
    >
      <path
        d='M17.942,22.849a11.588,11.588,0,0,0,8.3-.714,5.077,5.077,0,0,0-9.271-3.068m.969,3.782v0a7.812,7.812,0,0,0-.967-3.778m.967,3.782v.13a15.248,15.248,0,0,1-15.692,0v-.134a7.846,7.846,0,0,1,14.724-3.778M14.25,7.154A4.154,4.154,0,1,1,10.1,3,4.154,4.154,0,0,1,14.25,7.154ZM24.4,9.923a3.231,3.231,0,1,1-3.231-3.231A3.231,3.231,0,0,1,24.4,9.923Z'
        transform='translate(-1.249 -2)'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};

export default UsersIcon;
