const Logo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 213 45.003'>
      <g transform='translate(-21 -14)'>
        <text
          transform='translate(76 30)'
          fill='#fff'
          fontSize='17'
          fontFamily='Montserrat-SemiBold, Montserrat'
          fontWeight='600'
        >
          <tspan x='0' y='0' fontFamily='Montserrat-SemiBold, Montserrat'>
            SH 2.0
          </tspan>
          <tspan fontSize='22'>
            <tspan x='0' y='23' className='semibold'>
              Alert System
            </tspan>
          </tspan>
        </text>
        <g transform='translate(21 15.824)'>
          <g>
            <path
              d='M407.185,489.813l-6.879-6.879a2.266,2.266,0,0,1-.663-1.6V469.7h-2.178v4.925a2.265,2.265,0,0,1-3.868,1.6l-5.589-5.589-19.177,19.177-3.2-3.2,20.78-20.779a2.264,2.264,0,0,1,3.2,0l3.324,3.323v-1.721a2.265,2.265,0,0,1,2.266-2.266h6.709a2.265,2.265,0,0,1,2.265,2.266v12.961l6.216,6.216Z'
              transform='translate(-365.628 -465.166)'
              fill='#57a4ff'
            />
          </g>
          <path
            d='M24.279,6.616A14.439,14.439,0,0,1,13.87,2.944,14.439,14.439,0,0,1,3.461,6.616,14.514,14.514,0,0,0,13.87,24.3,14.514,14.514,0,0,0,24.279,6.616Z'
            transform='translate(8.511 16.819)'
            fill='none'
            stroke='#fa141b'
            strokeLinecap='round'
            strokeWidth='4'
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
