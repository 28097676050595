import { configureStore } from '@reduxjs/toolkit';

import channelsReducer from './slices/channels-slice';
import clientsReducer from './slices/clients-slice';
import usersReducer from './slices/users-slice';

const store = configureStore({
  reducer: {
    channels: channelsReducer,
    clients: clientsReducer,
    users: usersReducer,
  },
});

export default store;
